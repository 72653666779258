import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_row = _resolveComponent("el-row");

  return _openBlock(), _createBlock(_component_el_row, {
    align: $props.align,
    justify: $props.justify,
    style: {
      "flex": "1 1 auto",
      "flex-flow": "row",
      "overflow": "hidden"
    }
  }, {
    default: _withCtx(function () {
      return [_renderSlot(_ctx.$slots, "default")];
    }),
    _: 3
  }, 8, ["align", "justify"]);
}