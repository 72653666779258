export default {
  props: {
    align: {
      type: String,
      required: false,
      default: 'top'
    },
    justify: {
      type: String,
      required: false,
      default: 'center'
    }
  }
};