import HttpClient from './HttpClient';

export default class Coupon {

    constructor (coupon = null) {
        this.id = null;
        this.discount = null;
        this.user_value = null;
        this.used = null;
        this.cash_off = null;
        this.revoked = null;
        this.fs_coupon_id = null;
        this.validated = false;
        this.fs_path = null;
        this.products = null;

        if (coupon && typeof coupon === 'object') {
            for (const k in coupon) {
                this[k] = coupon[k];
            }
        }
    }

    static async fetch (code) {
        let payload = {
            path: `/laravel/rest/coupons/${code}`
        };

        const client = new HttpClient(payload);
        const resp = await client.get();

        if (!resp) {
            throw new Error('Empty response');
        }

        if (!(resp.status && resp.status === 200)) {
            throw new Error(resp.status);
        }
        
        if (!(resp.data && Object.values(resp.data).length)) {
            throw new Error('Error fetching coupon');
        }

        return new Coupon(resp.data);
    }
}