import "core-js/modules/es.function.name.js";
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-7973d608"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "flex-row flex-row-start fullwidth",
  id: "acct-view-title"
};
var _hoisted_2 = {
  class: "flex-col fullwidth"
};
var _hoisted_3 = {
  class: "flex-row flex-between",
  id: "container-inner"
};
var _hoisted_4 = {
  class: "size-page-header"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("h1", _hoisted_4, _toDisplayString($props.displayText || _ctx.$route.name), 1), _renderSlot(_ctx.$slots, "button", {}, undefined, true)])])]);
}