import "core-js/modules/es.object.keys.js";
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
import { inject } from 'vue';
import CartProduct from '@/components/CartProduct';
export default {
  setup: function setup(__props) {
    var cart = inject('cart');
    return function (_ctx, _cache) {
      var _component_el_row = _resolveComponent("el-row");

      var _component_el_col = _resolveComponent("el-col");

      return _openBlock(), _createBlock(_component_el_row, {
        class: "products fullwidth"
      }, {
        default: _withCtx(function () {
          return [_createVNode(_component_el_col, null, {
            default: _withCtx(function () {
              return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(cart).products, function (product, path, index) {
                return _openBlock(), _createElementBlock(_Fragment, {
                  key: path
                }, [_createVNode(_component_el_row, {
                  align: "middle",
                  justify: "center",
                  class: "cart-product-row"
                }, {
                  default: _withCtx(function () {
                    return [_createVNode(_unref(CartProduct), {
                      class: "fullwidth fullheight",
                      cartProduct: product,
                      onSetQuantity: _cache[0] || (_cache[0] = function (data) {
                        return _unref(cart).setQuantity(data);
                      })
                    }, null, 8, ["cartProduct"])];
                  }),
                  _: 2
                }, 1024), index < Object.keys(_unref(cart).products).length - 1 ? (_openBlock(), _createBlock(_component_el_row, {
                  key: 0,
                  style: {
                    borderBottom: '1px solid lightgray'
                  }
                })) : _createCommentVNode("", true)], 64);
              }), 128)), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(cart).upgrades, function (upgrade, index) {
                return _openBlock(), _createElementBlock(_Fragment, {
                  key: upgrade.path
                }, [_createVNode(_component_el_row, {
                  align: "middle",
                  justify: "center",
                  class: "cart-product-row"
                }, {
                  default: _withCtx(function () {
                    return [_createVNode(_unref(CartProduct), {
                      class: "fullwidth fullheight",
                      isUpgrade: true,
                      cartProduct: upgrade,
                      onRmUpgrade: _cache[1] || (_cache[1] = function (data) {
                        return _unref(cart).removeUpgrade(data);
                      })
                    }, null, 8, ["cartProduct"])];
                  }),
                  _: 2
                }, 1024), index < Object.keys(_unref(cart).products).length - 1 ? (_openBlock(), _createBlock(_component_el_row, {
                  key: 0,
                  style: {
                    borderBottom: '1px solid lightgray'
                  }
                })) : _createCommentVNode("", true)], 64);
              }), 128))];
            }),
            _: 1
          })];
        }),
        _: 1
      });
    };
  }
};